// TODO: Fix eslint issues the next time this file is edited.
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { ExtendCSS, Flex, Text, Link } from 'vcc-ui';
import { Image_DEPRECATED } from '@vcc-package/media/DEPRECATED';
import SectionContained from '@vcc-www/page-section/DEPRECATED_BROKEN_SectionContained';
import HighlightsPartnerLogosProps from './HighlightsPartnerLogos.props';

const HighlightsPartnerLogos: React.FC<HighlightsPartnerLogosProps> = ({
  content,
  ...params
}) => {
  const { logoItems, heading } = content;
  return (
    <SectionContained {...params}>
      <Text variant="ootah" subStyle="emphasis" extend={partnerLogosHeader}>
        {heading}
      </Text>
      {!logoItems?.length ? null : (
        <>
          <Flex extend={contentWrapper}>
            {logoItems.map((itemlist, i: number) => {
              const { title, image, link } = itemlist || {};

              return (
                <Flex extend={contentInnerCSS} key={title}>
                  {image?.src && (
                    <Link href={link} target="_blank">
                      <Image_DEPRECATED
                        src={image?.src}
                        title={image?.title}
                        alt={image?.alt}
                        loading="lazy"
                        extend={imageStyle(
                          `${image?.width}`,
                          `${image?.height}`,
                        )}
                      />
                    </Link>
                  )}
                </Flex>
              );
            })}
          </Flex>
        </>
      )}
    </SectionContained>
  );
};

export default HighlightsPartnerLogos;

const partnerLogosHeader: ExtendCSS = ({ theme: { baselineGrid } }) => ({
  marginTop: baselineGrid * 4,
  marginBottom: baselineGrid * 4,
  textAlign: 'center',
});

const imageStyle: (width: string, height: string) => ExtendCSS = (
  width,
  height,
) => ({
  '& img': {
    width,
    height,
  },
});

const contentWrapper: ExtendCSS = () => ({
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  flexWrap: 'wrap',
  untilM: {
    flexDirection: 'column',
    '& img': {
      width: 'auto',
    },
  },
});

const contentInnerCSS: ExtendCSS = ({ theme: { baselineGrid } }) => ({
  margin: baselineGrid * 4,
  alignSelf: 'center',
  '& a': {
    padding: 0,
  },
  '& img': {
    filter: 'grayscale(100%)',
    opacity: 0.5,
    '&:hover': {
      filter: 'none',
      opacity: 1,
    },
  },
});
